// Facebook && Turbo friendship

var fbRoot;

function saveFacebookRoot() {
  const temp = document.querySelector('#fb-root');
  if (temp) {
    fbRoot = temp;
    temp.remove();
  }
}

function restoreFacebookRoot() {
  if (typeof fbRoot !== 'undefined' && fbRoot !== null) {
    const temp = document.querySelector('#fb-root');
    if (temp) {
      temp.replaceWith(fbRoot);
    } else {
      document.querySelector('body').append(fbRoot);
    }
  }

  if (typeof FB !== 'undefined' && FB !== null) {
    FB.XFBML.parse();
  }
}

document.addEventListener('turbo:before-fetch-request', saveFacebookRoot);
document.addEventListener('turbo:load', restoreFacebookRoot);
