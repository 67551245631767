import Rails from '@rails/ujs'
import '@hotwired/turbo-rails'
import 'lite-youtube-embed'
import 'lite-youtube-embed/src/lite-yt-embed.css'
import 'controllers/app'
import 'custom/turbo_facebook'
import 'custom/fix_safari_pics'
import 'custom/analytics'

import '@fortawesome/fontawesome-free/css/all'
import 'swiper/swiper-bundle.min.css'
import 'stylesheets/application.scss'

Rails.start()
