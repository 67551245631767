import { Controller } from 'stimulus'

const randomId = () => Math.random().toString(36).substr(2, 9)
const findChildren = (el, tagName = 'LI') => [...el.children].filter(el => el.tagName === tagName)

export default class extends Controller {
  lists
  listPath = []

  connect () {
    // prepare structure
    const root = this.element.querySelector('ul')
    const children = findChildren(root)

    this.traverse(root, children)

    // assign
    this.lists = this.element.querySelectorAll('ul')
    this.listPath.push(this.lists[0])

    Array.from(this.lists).map(item => item.addEventListener('click', this.forward))
  }

  disconnect () {
    this.lists = undefined
    this.listPath = []
  }

  traverse = (baseNode, children, iter = randomId()) => {
    baseNode.setAttribute('data-list-id', `list-${iter}`)

    for (let child of children) {
      const hasGranChild = [...child.children].filter(el => el.tagName === 'UL').length

      if (hasGranChild) {
        const newIter = randomId()
        const links = findChildren(child, 'BUTTON')
        links[0].setAttribute('data-list-ref', `list-${newIter}`)

        let newChild = [...child.children].find(el => el.tagName === 'UL');
        const granChildren = findChildren(newChild)

        this.traverse(newChild, granChildren, newIter)
      }
    }
  }

  back(e) {
    e.stopPropagation()
    e.preventDefault()

    if (this.listPath.length < 2) {
      return false
    }

    const cl = this.listPath.pop()
    cl.classList.remove('active-list')
    this.listPath[this.listPath.length - 1].classList.remove('hidden')
    cl.classList.add('hidden')
  }

  forward = (e) => {
    e.stopPropagation();

    const link = e.target
    const listId = link.dataset.listRef

    if (listId) {
      e.preventDefault();
      const list = [...this.lists].find(el => el.dataset.listId === listId)
      list.classList.add('active-list')
      list.classList.remove('hidden')
      this.listPath[this.listPath.length - 1].classList.add('hidden')
      this.listPath.push(list)
    }
  }
}
