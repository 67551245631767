// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import Autosave from 'stimulus-rails-autosave'
import Reveal from 'stimulus-reveal-controller'
import ScrollTo from 'stimulus-scroll-to'
import { Tabs, Slideover, Popover } from 'tailwindcss-stimulus-components'
import Dropdown from 'stimulus-dropdown'

const application = Application.start()

require.context('images')

const context = require.context('controllers/common', true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))

application.register('autosave', Autosave)
application.register('reveal', Reveal)
application.register('scroll-to', ScrollTo)
application.register('tabs', Tabs)
application.register('dropdown', Dropdown)
application.register('slideover', Slideover)
application.register('popover', Popover)
