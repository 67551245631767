import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.min.css'

export default class extends Controller {
  gallery
  thumbs
  galleryOptionsValue
  thumbsOptionsValue

  static targets = [ 'gallery', 'thumbs' ]

  connect () {
    this.thumbs = new Swiper(this.thumbsTarget, {
      ...this.thumbsDefaultOptions,
      ...this.thumbsOptionsValue
    })

    this.gallery = new Swiper(this.galleryTarget, {
      ...this.galleryDefaultOptions,
      ...this.galleryOptionsValue,
      ...{ thumbs: { swiper: this.thumbs } }
    })

    this.gallery.controller.control = this.thumbs
  }

  disconnect () {
    this.gallery.destroy()
    this.gallery = undefined

    this.thumbs.destroy()
    this.thumbs = undefined
  }

  get galleryDefaultOptions() {
    return {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    }
  }

  get thumbsDefaultOptions() {
    return {
      slidesPerView: 'auto',
      spaceBetween: 10,
      watchSlidesProgress: true,
      slideToClickedSlide: true,
      breakpoints: {
        320: {
          slidesPerView: 3
        },
        480: {
          slidesPerView: 4
        },
        640: {
          slidesPerView: 6
        },
        960: {
          slidesPerView: 6
        },
        1200: {
          slidesPerView: 8
        }
      }
    }
  }
}
