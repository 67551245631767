// https://playground.cookieconsent.orestbida.com/
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import * as CookieConsent from 'vanilla-cookieconsent'

window.onload = (event) => {
  CookieConsent.run({
    guiOptions: {
      consentModal: {
        layout: "bar inline",
        position: "bottom",
        equalWeightButtons: true,
        flipButtons: false
      },
      preferencesModal: {
        layout: "box",
        position: "right",
        equalWeightButtons: false,
        flipButtons: false
      }
    },
    categories: {
      necessary: {
        readOnly: true
      },
      analytics: {},
    },
    language: {
      default: "uk",
      autoDetect: false,
      translations: {
        uk: {
          consentModal: {
            title: "Ми використовуємо cookies 🍪",
            description: 'Щоб забезпечити вам найкращий досвід на нашому сайті, ми використовуємо cookies. Продовжуючи перегляд, ви погоджуєтеся з нашим використанням cookies. Для отримання додаткової інформації або управління вашими налаштуваннями, будь ласка, відвідайте нашу Політику щодо cookies.',
            acceptAllBtn: "Прийняти cookies",
            acceptNecessaryBtn: "Відхилити всі",
            showPreferencesBtn: "Управління налаштуваннями",
            footer: "<a href='/privacy'>Політика конфіденційності</a>"
          },
          preferencesModal: {
            title: "Центр налаштувань згоди",
            acceptAllBtn: "Прийняти cookies",
            acceptNecessaryBtn: "Відхилити всі",
            savePreferencesBtn: "Зберегти налаштування",
            closeIconLabel: "Закрити вікно",
            sections: [
              {
                title: "Використання cookies",
                description: "Cookies – це невеликий набір даних, який зберігається на вашому пристрої під час відвідування вебсайту. Вони допомагають нам запам'ятовувати ваші налаштування, аналізувати трафік та покращувати функціональність сайту. Завдяки cookies, ми можемо забезпечити вам найкращий досвід користування нашим сайтом."
              },
              {
                title: "Cookies, що є абсолютно необхідними <span class=\"pm__badge\">Завжди ввімкнено</span>",
                description: "Ці cookies є абсолютно необхідними для коректної роботи сайту, забезпечуючи доступ до основних функцій. Вони завжди увімкнені і не можуть бути вимкнені.",
                linkedCategory: "necessary"
              },
              {
                title: "Cookies для аналітики",
                description: "Ці cookies допомагають нам збирати дані про використання сайту для його покращення. Вони дозволяють аналізувати, як ви взаємодієте з сайтом, і допомагають нам покращити ваш досвід.",
                linkedCategory: "analytics"
              },
            ]
          }
        }
      }
    }
  })
}
