import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.min.css'

export default class extends Controller {
  gallery
  galleryOptionsValue

  static targets = [ 'gallery' ]

  connect () {
    this.gallery = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.galleryOptionsValue
    })
  }

  disconnect () {
    this.gallery.destroy()
    this.gallery = undefined
  }

  get defaultOptions () {
    return {
      autoplay: {
        delay: 6000,
      },
      loop: true,
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: { el: '.swiper-pagination', clickable: true, bulletClass: 'swiper-pagination-bullet hidden lg:inline-flex w-3 h-3 bg-white' },
      navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
    }
  }
}
